.my-name-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.my-picture {
  width: 300px;
  border-radius: 3px;
}

.my-detail {
  display: flex;
  flex-direction: column;

  text-align: center;
  margin: 0px;
}
.my-name {
  font-size: 25px;
  text-decoration: underline;
}
.my-bio {
  text-align: justify;
}
