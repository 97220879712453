.nav-bar {
  display: flex;
  justify-content: center;
  font-size: 20px;
}
.link-text {
  color: black;
  text-decoration: none;
  margin: 20px;
}
